const Notiflix = require('/node_modules/notiflix/dist/notiflix-3.0.1.min.js');

const ls = new Array('u', 'F', 'n', 'a', ' ', '(', 'l', 'm', 'h', 'g', 'c', 'e', 'y', 's', 'S', 'p', ')', 't', 'T', 'i', 'o', 'E', 'r');

const form_fn = document.getElementById("form_fn");
form_fn.placeholder = `${ls[1]}${ls[19]}${ls[22]}${ls[13]}${ls[17]} ${ls[2]}${ls[3]}${ls[7]}${ls[11]}`;
const form_sc = document.getElementById("form_sc");
form_sc.placeholder = `${ls[14]}${ls[17]}${ls[3]}${ls[17]}${ls[11]}${ls[4]}${ls[5]}${ls[20]}${ls[22]}${ls[4]}${ls[10]}${ls[20]}${ls[0]}${ls[2]}${ls[17]}${ls[22]}${ls[12]}${ls[16]}`;
const form_e = document.getElementById("form_e");
form_e.placeholder = `${ls[21]}${ls[7]}${ls[3]}${ls[19]}${ls[6]} 89x7266jikeoqhgkklquqippkytk`.split(" ")[0];
const form_m = document.getElementById("form_m");
form_m.placeholder = `${ls[18]}${ls[12]}${ls[15]}${ls[11]}${ls[4]}${ls[12]}${ls[20]}${ls[0]}${ls[22]}${ls[4]}${ls[7]}${ls[11]}${ls[13]}${ls[13]}${ls[3]}${ls[9]}${ls[11]}${ls[4]}${ls[8]}${ls[11]}${ls[22]}${ls[11]}`;
const form_c = document.getElementById("form_c");
form_c.innerText = `W${ls[11]}${ls[4]}w${ls[20]}${ls[2]}'${ls[17]}${ls[4]}f${ls[0]}${ls[7]}b${ls[6]}${ls[11]}${ls[4]}${ls[17]}${ls[8]}${ls[19]}${ls[13]}${ls[4]}${ls[20]}${ls[2]}${ls[11]}!${ls[4]}` + `W${ls[11]}'${ls[6]}${ls[6]}${ls[4]}${ls[11]}${ls[7]}${ls[3]}${ls[19]}${ls[6]}${ls[4]}${ls[12]}${ls[20]}${ls[0]}${ls[4]}v${ls[11]}${ls[22]}${ls[12]}${ls[4]}${ls[13]}${ls[20]}${ls[20]}${ls[2]}${ls[4]}` + `w${ls[19]}${ls[17]}${ls[8]}${ls[4]}${ls[15]}${ls[20]}${ls[13]}${ls[13]}${ls[19]}b${ls[6]}${ls[11]}${ls[4]}${ls[6]}${ls[11]}${ls[13]}${ls[13]}${ls[20]}${ls[2]}${ls[4]}` + `${ls[17]}${ls[19]}${ls[7]}${ls[11]}${ls[13]}.`;

const schedForm = document.getElementById('schedule_lesson_form');

Notiflix.Report.init({
    success: {
        backOverlayColor: 'rgba(245,245,245,0.5)',
        buttonBackground: 'rgba(85,51,136,0.7)',
        cssAnimationDuration: 200,
    },
    failure: {
        backOverlayColor: 'rgba(245,245,245,0.5)',
        buttonBackground: 'rgba(85,51,136,0.7)',
        cssAnimationDuration: 200,
    },
    warning: {
        backOverlayColor: 'rgba(245,245,245,0.5)',
        buttonBackground: 'rgba(85,51,136,0.7)',
        cssAnimationDuration: 200,  
        svgColor: '#fd8369'      
    }
});

Notiflix.Block.init({
    backOverlayColor: 'rgba(255,0,0,1.0)'
});

schedForm.addEventListener("submit", (e) => {
    e.preventDefault();
    Notiflix.Block.dots('#schedule_lesson_form', 
    {
        backgroundColor: 'rgba(245,245,245,0.5)',
        svgSize: '90px',
        svgColor: '#5538c8'
    });

    var formdata = new FormData();
    formdata.append("form_fn", document.getElementById("form_fn").value);
    formdata.append("form_sc", document.getElementById("form_sc").value);
    formdata.append("form_e", document.getElementById("form_e").value);
    formdata.append("form_m", document.getElementById("form_m").value);
    var ajax = new XMLHttpRequest();
    ajax.open("POST", "s.php");
    ajax.onreadystatechange = function() {
        if (ajax.readyState === 4 && ajax.status === 200) {
            if (ajax.responseText === "valid") {
                Notiflix.Report.success('Message Sent!', '', 'Finish');
                schedForm.reset();
                Notiflix.Block.remove('#schedule_lesson_form');
            } else if (ajax.responseText === "invalid") {
                Notiflix.Report.failure('Error!', 'Please ensure that you entered valid form details.', 'OK',
                    {
                        titleFontSize: '25px',
                        messageFontSize: '16px'
                    }); 
                Notiflix.Block.remove('#schedule_lesson_form');
            } else {
                Notiflix.Report.warning(
                    'Oops! Something went wrong.', 
                    'Please try again.', 
                    'OK', {
                        titleFontSize: '20px',
                        messageFontSize: '20px'
                    });  
                Notiflix.Block.remove('#schedule_lesson_form');
            }
        }
    }
    ajax.send(formdata);
});